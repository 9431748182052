<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary">
          <img
            src="@/assets/images/logo/logo-mercadohit.svg"
            width="150"
          >
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Inicie a sua jornada de sucesso 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Cadastre-se agora
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- username -->
              <b-form-group
                label="Nome Completo"
                label-for="usuarioNome"
              >
                <validation-provider
                  #default="{ errors }"
                  name="usuarioNome"
                  vid="usuarioNome"
                  rules="required|validarNomeSobrenome"
                >
                  <b-form-input
                    id="usuarioNome"
                    v-model="usuarioNome"
                    name="usuarioNome"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nome e Sobrenome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-row>
                <b-col>
                  <!-- cpf -->
                  <b-form-group
                    label="Data Nascimento"
                    label-for="usuarioDtNascimento"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="usuarioDtNascimento"
                      vid="usuarioDtNascimento"
                      rules="required"
                    >
                      <the-mask
                        id="usuarioDtNascimento"
                        v-model="usuarioDtNascimento"
                        :state="errors.length > 0 ? false:null"
                        :mask="['##/##/####']"
                        :masked="false"
                        class="form-control"
                        placeholder="DD/MM/AAAA"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="CPF"
                    label-for="usuarioCpf"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="usuarioCpf"
                      vid="usuarioCpf"
                      rules="required|cpf"
                    >
                      <the-mask
                        id="usuarioCpf"
                        v-model="usuarioCpf"
                        :state="errors.length > 0 ? false:null"
                        :mask="['###.###.###-##']"
                        class="form-control"
                        placeholder="000.000.000-00"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- celular -->
              <b-form-group
                label="WhatsApp"
                label-for="usuarioCelular"
              >
                <validation-provider
                  #default="{ errors }"
                  name="usuarioCelular"
                  vid="usuarioCelular"
                  rules="required"
                >
                  <the-mask
                    id="usuarioCelular"
                    v-model="usuarioCelular"
                    :state="errors.length > 0 ? false:null"
                    :mask="['(##) #####-####']"
                    class="form-control"
                    placeholder="(00) 00000-0000"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="usuarioEmail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="usuarioEmail"
                  vid="usuarioEmail"
                  rules="required|email"
                >
                  <b-form-input
                    id="usuarioEmail"
                    v-model="usuarioEmail"
                    name="usuarioEmail"
                    :state="errors.length > 0 ? false:null"
                    placeholder="email@exemplo.com"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-row>

                <b-col>

                  <!-- senha -->
                  <b-form-group
                    label-for="usuarioSenha"
                    label="Senha"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="usuarioSenha"
                      vid="usuarioSenha"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="usuarioSenha"
                          v-model="usuarioSenha"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false:null"
                          name="usuarioSenha"
                          placeholder="············"
                          autocomplete="off"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>

                <b-col>

                  <!-- senha -->
                  <b-form-group
                    label-for="usuarioSenhaConfirmacao"
                    label="Confirme Senha"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="usuarioSenhaConfirmacao"
                      vid="usuarioSenhaConfirmacao"
                      rules="required|confirmed:usuarioSenha"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="usuarioSenhaConfirmacao"
                          v-model="usuarioSenhaConfirmacao"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false:null"
                          name="usuarioSenhaConfirmacao"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>

              </b-row>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="aceitoPolitica"
                  vid="aceitoPolitica"
                  rules="required"
                >
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="isChecked"
                  name="aceitoPolitica"
                  :state="errors.length > 0 ? false : isChecked ? true : null"
                >
                  Aceito os
                  <b-link @click="abrirTermosUso()">termos de uso </b-link> 
                  e 
                  <b-link @click="abrirPoliticaPrivacidade()">política de privacidade</b-link> 
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid || respostaApi || !isChecked"
              >
                Finalizar
                <b-spinner
                  v-if="respostaApi"
                  small
                  label="Carregando"
                />
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Já tem uma conta?</span>
            <b-link :to="{name:'autenticacao-login'}">
              <span>&nbsp;Acesse agora</span>
            </b-link>
          </p>

        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>

    <!-- Modal Política de Privacidade -->
    <b-modal
      id="politicaPrivacidade"
      ref="politicaPrivacidade"
      title="Termos de Política de Privacidade"
      size="lg"
      scrollable
      hide-footer
      no-close-on-backdrop
    >
      <b-card-body class="p-2">
        <politica-privacidade></politica-privacidade>
      </b-card-body>
    </b-modal>


  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import {
 BIconEnvelope, IconsPlugin, BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BCardBody, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

import DisableAutocomplete from 'vue-disable-autocomplete'

import { TheMask } from 'vue-the-mask'

import { cpf, cnpj, cpfOrCnpj } from 'vee-cpf-cnpj'

// ERROS DOS CAMPOS
import { localize } from 'vee-validate'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

import PoliticaPrivacidade from '@/views/pages/documentos/PoliticaPrivacidade.vue';
import moment from 'moment';

library.add(faCoffee)

extend('cpf', cpf)
extend('cnpj', cnpj)
extend('cpf_or_cnpj', cpfOrCnpj)

extend('validarNomeSobrenome', {
  validate: (value) => {
    const nomeSobrenome = value.split(' ');

    if (nomeSobrenome.length < 2) {
      return 'Digite seu nome completo (nome e sobrenome).';
    }

    return true;
  },
  message: 'Campo inválido',
})


localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      usuarioNome: {
        required: 'O nome é obrigatório.',
      },
      usuarioCpf: {
        required: 'O cpf é obrigatório.',
        cpf: 'O cpf é inválido.',
      },
      usuarioDtNascimento: {
        required: 'A data é obrigatória.'
      },
      usuarioCidade: {
        required: 'A cidade é obrigatória.',
      },
      usuarioEstado: {
        required: 'O estado é obrigatório.',
      },
      usuarioCelular: {
        required: 'O WhatsApp é obrigatório.',
      },
      usuarioEmail: {
        required: 'O email é obrigatório.',
        email: 'Email inválido.',
      },
      usuarioSenha: {
        required: 'A senha é obrigatória.',
      },
      usuarioSenhaConfirmacao: {
        required: 'Confirmação obrigatória.',
        confirmed: 'A senhas não estão iguais.',
      },
    },
  },
})

export default {
  components: {
    BIconEnvelope,
    IconsPlugin,
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
    // autocomplete off
    DisableAutocomplete,
    TheMask,
    FontAwesomeIcon,
    PoliticaPrivacidade,
    flatPickr,
    moment
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      estados: [
        { value: null, text: 'UF' },
        { value: 'AC', text: 'Acre' },
        { value: 'AL', text: 'Alagoas' },
        { value: 'AP', text: 'Amapá' },
        { value: 'AM', text: 'Amazonas' },
        { value: 'BA', text: 'Bahia' },
        { value: 'CE', text: 'Ceará' },
        { value: 'DF', text: 'Distrito Federal' },
        { value: 'ES', text: 'Espírito Santo' },
        { value: 'GO', text: 'Goiás' },
        { value: 'MA', text: 'Maranhão' },
        { value: 'MT', text: 'Mato Grosso' },
        { value: 'MS', text: 'Mato Grosso do Sul' },
        { value: 'MG', text: 'Minas Gerais' },
        { value: 'PA', text: 'Pará' },
        { value: 'PB', text: 'Paraíba' },
        { value: 'PR', text: 'Paraná' },
        { value: 'PE', text: 'Pernambuco' },
        { value: 'PI', text: 'Piauí' },
        { value: 'RJ', text: 'Rio de Janeiro' },
        { value: 'RN', text: 'Rio Grande do Norte' },
        { value: 'RS', text: 'Rio Grande do Sul' },
        { value: 'RO', text: 'Rondônia' },
        { value: 'RR', text: 'Roraima' },
        { value: 'SC', text: 'Santa Catarina' },
        { value: 'SP', text: 'São Paulo' },
        { value: 'SE', text: 'Sergipe' },
        { value: 'TO', text: 'Tocantins' },
      ],
      isChecked: false,
      usuarioNome: '',
      usuarioCpf: '',
      usuarioDtNascimento: '',
      usuarioCelular: '',
      usuarioEmail: '',
      usuarioSenha: '',
      usuarioSenhaConfirmacao: '',
      sideImg: require('@/assets/images/pages/login-v2.png'),
      // validation
      required,
      email,
      respostaApi: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/login-v2.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
  },
  methods: {

    formatarData(date) {
        return moment(date, "DDMMYYYY").format("DD/MM/YYYY");
    },

    abrirTermosUso() {
      const politicaUrl = 'https://s3-mercado-hit.s3.amazonaws.com/documentacao/termos-politicas/Termo-de-Uso-Mercado-Hit.pdf';
      window.open(politicaUrl, '_blank');
    },

    abrirPoliticaPrivacidade() {
      const politicaUrl = 'https://s3-mercado-hit.s3.amazonaws.com/documentacao/termos-politicas/Politica-Privacidade-Mercado-Hit.pdf';
      window.open(politicaUrl, '_blank');
    },

    register() {
      if(this.isChecked) {

          this.$refs.registerForm.validate().then(success => {
          if (success) {
            this.respostaApi = true

            useJwt.usuarioCompositorCadastro({
              nome: this.usuarioNome,
              cpf: this.usuarioCpf,
              dataNascimento: this.formatarData(this.usuarioDtNascimento),
              celular: this.usuarioCelular,
              email: this.usuarioEmail,
              senha: this.usuarioSenha,
            })
              .then(response => {

                const iconHtml = `<svg width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>`;
        
                this.$swal({
                  title: 'Enviamos um email para você!',
                  html: 'Para confirmar o seu cadastro, enviamos um email para <strong>'+this.usuarioEmail+'</strong><br> Verifique a caixa de entrada ou lixo eletrônico!',
                  iconHtml: iconHtml,
                  customClass: {
                    icon: 'iconEmail',
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.isConfirmed) {
                    this.$router.push({ name: 'autenticacao-login' })
                  } else {
                    this.$router.push({ name: 'autenticacao-login' })
                  }
                })

              })
              .catch(error => {

                this.$swal({
                  title: 'Erro!',
                  text: error.response.data.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .finally(() => {
                this.respostaApi = false
              })
          }
        })

      } else {

        this.$swal({
          title: 'Aceite obrigatório dos termos!',
          text: 'É necessário concordar com os termos de uso e política de privacidade antes de prosseguir com o cadastro. O aceite é obrigatório para continuar o processo.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.brand-text img {
  vertical-align: baseline;
}

.iconEmail {
  border: .25em solid rgba(165,220,134,.3) !important;
}

.iconEmail svg {
  fill: #a5dc86;
}

</style>
